@import '../../mixins';

.product-card {
  overflow: hidden;

  width: rem(410);
  height: auto;
  display: flex;

  container-name: product-card;
  container-type: inline-size;

  @include mediaBigDesktop {
    width: big(410);
  }

  @include mediaLaptop {
    width: rem(300);
  }

  @include mediaTablet {
    width: rem(329);
  }

  @include mediaMobile {
    width: rem(280);
  }

  & > form {
    position: relative;

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: start;
    gap: rem(20);

    background-color: var(--bg-white);
    border-radius: var(--radius-common);

    transition: box-shadow var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaMobile {
      gap: rem(15);
    }

    @include hover {
      & .product-card {
        &__image {
          & img {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  &__top {
    position: relative;

    padding: rem(20);
    width: 100%;
    height: rem(500);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-shrink: 0;
    gap: rem(69);

    border-radius: var(--radius-common);
    border: solid 1px var(--stroke-dark-secondary);
    overflow: hidden;
    background-color: var(--bg-light-extra);

    @include mediaBigDesktop {
      padding: big(20);
      height: big(500);
      gap: big(69);
    }

    @include mediaLaptop {
      padding: rem(20) rem(15) rem(20) rem(20);
      height: rem(366);
      gap: rem(59);

      border: 0;
    }

    @include mediaTablet {
      padding: rem(20) rem(25) rem(20) rem(20);
      height: rem(427);
    }

    @include mediaMobile {
      padding: rem(15);
      height: rem(326);
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;
    height: 100%;
    user-select: none;

    & img,
    & picture {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;

      pointer-events: none;

      transition: transform var(--animation-timing-medium) var(--cubic-bezier);
    }
    
    &_crutch {
      padding: rem(20);
      
      background-color: var(--bg-white);
      
      & img {
        object-fit: contain;
      }
    }
  }

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaTablet {
      gap: rem(10);
    }
  }

  &__tag-list {
    position: relative;
    z-index: 1;

    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    place-content: start;
    flex-wrap: wrap;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    @include mediaMobile {
      gap: rem(5);
    }

    & .tag {
      @include mediaLaptop {
        @include smallTag {}
      }
    }
  }

  &__menu {
    position: relative;
    z-index: 1;

    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    place-content: start;
    flex-wrap: wrap;
    gap: rem(5);

    @include mediaBigDesktop {
      gap: big(5);
    }

    & > .button {
      @include mediaLaptop {
        padding: rem(6);
      }
    }
  }

  &__wishlist.button {
    background-color: var(--bg-white);

    @include mediaLaptop {
      @include mediumButtonIcon {}
    }

    &.active, &.voted {
      & .button {
        &__icon {
          fill: var(--accent-light-primary);
        }
      }

      @include hover {
        & .button {
          &__icon {
            fill: var(--text-dark-primary);
          }
        }
      }

      &.animation {
        @include hover {
          & .button {
            &__icon {
              fill: var(--elements-white);
            }
          }
        }
      }
    }
  }

  &__bottom {
    padding: 0;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    @include mediaLaptop {
      gap: rem(12);
    }

    @include mediaTablet {
      gap: rem(10);
    }
  }

  &__info {
    padding-right: rem(20);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(5);

    @include mediaBigDesktop {
      padding-right: big(20);
      gap: big(5);
    }
  }

  &__weight {
    color: var(--text-dark-secondary);
    font: var(--font-body-xs);
  }

  &__name {
    margin-bottom: auto;
    display: -webkit-box;

    color: var(--text-dark-primary);
    font: var(--font-card-title-M);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    text-decoration: none;

    overflow: hidden;

    transition: color var(--animation-timing) var(--cubic-bezier);

    @include hover {
      color: var(--accent-light-secondary);
    }

    &::before {
      @include pseudo(0) {}
    }
  }
  
  &__characteristics {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(10);
    padding: rem(10) 0;

    @include mediaBigDesktop {
      gap: big(10);
      padding: big(10) 0;
    }
    
    @include mediaTablet {
      padding: rem(8) 0;
    }

    & table {
      width: 100%;
      border-collapse: collapse;
    }

    & tbody {
      width: 100%;
    }

    & tr {
      width: 100%;

      &:first-child {
        & td {
          padding-top: 0;
        }
      }
    }

    & td {
      padding: rem(10) 0;

      color: var(--text-dark-primary);
      font: var(--font-body-S);
      
      border-bottom: solid 1px var(--stroke-light-primary);

      @include mediaBigDesktop {
        padding: big(10) 0;
        
        border-bottom-width: big(1);
      }
    
      @include mediaLaptop {
        padding: rem(10) 0;
      }

      @include mediaTablet {
        padding: rem(8) 0;
      }

      &:first-child {
       color: var(--text-dark-secondary);

       &::after {
        content: ":";
       }
      }

      &:last-child {
        text-align: end;
      }
    }
  }


  &__description {
    color: var(--text-dark-secondary);
    font: var(--font-body-xs);
  }

  &__footer {
    margin-top: auto;

    padding-right: rem(20);
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: rem(20);

    @include mediaBigDesktop {
      padding-right: big(20);
      gap: big(20);
    }
  }

  &__price {
    position: absolute;
    left: 0;
    
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;

    transition: opacity calc(var(--animation-timing) / 2) var(--cubic-bezier) var(--animation-timing);

    & .price {
      &__sum {
        font: var(--font-body-L);
        font-weight: 400;
      }

      &__old {
        font: 400 rem(18)/150% var(--font-primary);

        @include mediaBigDesktop {
          font: 400 big(18)/150% var(--font-primary);
        }

        @include mediaLaptop {
          font: 400 rem(16)/150% var(--font-primary);
        }

        @include mediaTablet {
          font: 400 rem(14)/150% var(--font-primary);
        }
      }
    }
  }

  &__button {
    position: relative;
    z-index: 1;

    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: rem(10);

    pointer-events: none;

    @include mediaBigDesktop {
      grid-area: big(10);
    }

    /*@include hover {
      & ~ .product-card {
        &__price {
          @container product-card (max-width: #{rem(365)}) {
            opacity: 0;

            transition: opacity calc(var(--animation-timing) / 2) var(--cubic-bezier);
          }

          @supports not (container-type: inline-size) {
            @include media(#{rem(1600)}) {
              opacity: 0;

              transition: opacity calc(var(--animation-timing) / 2) var(--cubic-bezier);
            }
          }
        }
      }
    }*/

    & .button-product {
      pointer-events: all;
    }
  }

  & .availability {
    &__icon {
      @include mediaLaptop {
        width: rem(16);
        height: rem(16);
      }

      @include mediaTablet {
        width: rem(12);
        height: rem(12);
      }
    }
  }
}
