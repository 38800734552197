.product-card {
  overflow: hidden;
  width: 25.625rem;
  height: auto;
  display: flex;
  container-name: product-card;
  container-type: inline-size; }
  @media screen and (min-width: 160.0625rem) {
    .product-card {
      width: 16.01562vw; } }
  @media screen and (max-width: 106.25rem) {
    .product-card {
      width: 18.75rem; } }
  @media screen and (max-width: 85rem) {
    .product-card {
      width: 20.5625rem; } }
  @media screen and (max-width: 39.375rem) {
    .product-card {
      width: 17.5rem; } }
  .product-card > form {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: start;
    gap: 1.25rem;
    background-color: var(--bg-white);
    border-radius: var(--radius-common);
    transition: box-shadow var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .product-card > form {
        gap: 0.78125vw; } }
    @media screen and (max-width: 39.375rem) {
      .product-card > form {
        gap: 0.9375rem; } }
    @media (any-hover: hover) {
      .product-card > form:hover .product-card__image img {
        transform: scale(1.1); } }
  .product-card__top {
    position: relative;
    padding: 1.25rem;
    width: 100%;
    height: 31.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-shrink: 0;
    gap: 4.3125rem;
    border-radius: var(--radius-common);
    border: solid 1px var(--stroke-dark-secondary);
    overflow: hidden;
    background-color: var(--bg-light-extra); }
    @media screen and (min-width: 160.0625rem) {
      .product-card__top {
        padding: 0.78125vw;
        height: 19.53125vw;
        gap: 2.69531vw; } }
    @media screen and (max-width: 106.25rem) {
      .product-card__top {
        padding: 1.25rem 0.9375rem 1.25rem 1.25rem;
        height: 22.875rem;
        gap: 3.6875rem;
        border: 0; } }
    @media screen and (max-width: 85rem) {
      .product-card__top {
        padding: 1.25rem 1.5625rem 1.25rem 1.25rem;
        height: 26.6875rem; } }
    @media screen and (max-width: 39.375rem) {
      .product-card__top {
        padding: 0.9375rem;
        height: 20.375rem; } }
  .product-card__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    user-select: none; }
    .product-card__image img,
    .product-card__image picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      pointer-events: none;
      transition: transform var(--animation-timing-medium) var(--cubic-bezier); }
    .product-card__image_crutch {
      padding: 1.25rem;
      background-color: var(--bg-white); }
      .product-card__image_crutch img {
        object-fit: contain; }
  .product-card__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.9375rem; }
    @media screen and (min-width: 160.0625rem) {
      .product-card__head {
        gap: 0.58594vw; } }
    @media screen and (max-width: 85rem) {
      .product-card__head {
        gap: 0.625rem; } }
  .product-card__tag-list {
    position: relative;
    z-index: 1;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    place-content: start;
    flex-wrap: wrap;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .product-card__tag-list {
        gap: 0.39062vw; } }
    @media screen and (max-width: 39.375rem) {
      .product-card__tag-list {
        gap: 0.3125rem; } }
    @media screen and (max-width: 106.25rem) {
      .product-card__tag-list .tag {
        padding: 0.25rem 0.5rem;
        font-size: 0.625rem;
        letter-spacing: 0.0625rem; } }
  @media screen and (max-width: 106.25rem) and (min-width: 160.0625rem) {
    .product-card__tag-list .tag {
      padding: 0.15625vw 0.3125vw;
      font-size: 0.39062vw;
      letter-spacing: 0.03906vw; } }
  .product-card__menu {
    position: relative;
    z-index: 1;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    place-content: start;
    flex-wrap: wrap;
    gap: 0.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .product-card__menu {
        gap: 0.19531vw; } }
    @media screen and (max-width: 106.25rem) {
      .product-card__menu > .button {
        padding: 0.375rem; } }
  .product-card__wishlist.button {
    background-color: var(--bg-white); }
    @media screen and (max-width: 106.25rem) {
      .product-card__wishlist.button {
        width: 2.75rem;
        height: 2.75rem; } }
  @media screen and (max-width: 106.25rem) and (min-width: 160.0625rem) {
    .product-card__wishlist.button {
      width: 1.71875vw;
      height: 1.71875vw; } }
    @media screen and (max-width: 106.25rem) {
        .product-card__wishlist.button .button__icon {
          width: 1.25rem;
          height: 1.25rem; } }
    @media screen and (max-width: 106.25rem) and (min-width: 160.0625rem) {
      .product-card__wishlist.button .button__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }
    .product-card__wishlist.button.active .button__icon, .product-card__wishlist.button.voted .button__icon {
      fill: var(--accent-light-primary); }
    @media (any-hover: hover) {
      .product-card__wishlist.button.active:hover .button__icon, .product-card__wishlist.button.voted:hover .button__icon {
        fill: var(--text-dark-primary); } }
    @media (any-hover: hover) {
      .product-card__wishlist.button.active.animation:hover .button__icon, .product-card__wishlist.button.voted.animation:hover .button__icon {
        fill: var(--elements-white); } }
  .product-card__bottom {
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .product-card__bottom {
        gap: 0.39062vw; } }
    @media screen and (max-width: 106.25rem) {
      .product-card__bottom {
        gap: 0.75rem; } }
    @media screen and (max-width: 85rem) {
      .product-card__bottom {
        gap: 0.625rem; } }
  .product-card__info {
    padding-right: 1.25rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 0.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .product-card__info {
        padding-right: 0.78125vw;
        gap: 0.19531vw; } }
  .product-card__weight {
    color: var(--text-dark-secondary);
    font: var(--font-body-xs); }
  .product-card__name {
    margin-bottom: auto;
    display: -webkit-box;
    color: var(--text-dark-primary);
    font: var(--font-card-title-M);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    text-decoration: none;
    overflow: hidden;
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media (any-hover: hover) {
      .product-card__name:hover {
        color: var(--accent-light-secondary); } }
    .product-card__name::before {
      content: "";
      position: absolute;
      top: -0rem;
      left: -0rem;
      width: calc(100% + 0rem * 2);
      height: calc(100% + 0rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .product-card__name::before {
          top: 0vw;
          left: 0vw;
          width: calc(100% + 0vw * 2);
          height: calc(100% + 0vw * 2); } }
  .product-card__characteristics {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.625rem;
    padding: 0.625rem 0; }
    @media screen and (min-width: 160.0625rem) {
      .product-card__characteristics {
        gap: 0.39062vw;
        padding: 0.39062vw 0; } }
    @media screen and (max-width: 85rem) {
      .product-card__characteristics {
        padding: 0.5rem 0; } }
    .product-card__characteristics table {
      width: 100%;
      border-collapse: collapse; }
    .product-card__characteristics tbody {
      width: 100%; }
    .product-card__characteristics tr {
      width: 100%; }
      .product-card__characteristics tr:first-child td {
        padding-top: 0; }
    .product-card__characteristics td {
      padding: 0.625rem 0;
      color: var(--text-dark-primary);
      font: var(--font-body-S);
      border-bottom: solid 1px var(--stroke-light-primary); }
      @media screen and (min-width: 160.0625rem) {
        .product-card__characteristics td {
          padding: 0.39062vw 0;
          border-bottom-width: 0.03906vw; } }
      @media screen and (max-width: 106.25rem) {
        .product-card__characteristics td {
          padding: 0.625rem 0; } }
      @media screen and (max-width: 85rem) {
        .product-card__characteristics td {
          padding: 0.5rem 0; } }
      .product-card__characteristics td:first-child {
        color: var(--text-dark-secondary); }
        .product-card__characteristics td:first-child::after {
          content: ":"; }
      .product-card__characteristics td:last-child {
        text-align: end; }
  .product-card__description {
    color: var(--text-dark-secondary);
    font: var(--font-body-xs); }
  .product-card__footer {
    margin-top: auto;
    padding-right: 1.25rem;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .product-card__footer {
        padding-right: 0.78125vw;
        gap: 0.78125vw; } }
  .product-card__price {
    position: absolute;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
    transition: opacity calc(var(--animation-timing) / 2) var(--cubic-bezier) var(--animation-timing); }
    .product-card__price .price__sum {
      font: var(--font-body-L);
      font-weight: 400; }
    .product-card__price .price__old {
      font: 400 1.125rem/150% var(--font-primary); }
      @media screen and (min-width: 160.0625rem) {
        .product-card__price .price__old {
          font: 400 0.70312vw/150% var(--font-primary); } }
      @media screen and (max-width: 106.25rem) {
        .product-card__price .price__old {
          font: 400 1rem/150% var(--font-primary); } }
      @media screen and (max-width: 85rem) {
        .product-card__price .price__old {
          font: 400 0.875rem/150% var(--font-primary); } }
  .product-card__button {
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 0.625rem;
    pointer-events: none;
    /*@include hover {
      & ~ .product-card {
        &__price {
          @container product-card (max-width: 22.8125rem) {
            opacity: 0;

            transition: opacity calc(var(--animation-timing) / 2) var(--cubic-bezier);
          }

          @supports not (container-type: inline-size) {
            @include media(100rem) {
              opacity: 0;

              transition: opacity calc(var(--animation-timing) / 2) var(--cubic-bezier);
            }
          }
        }
      }
    }*/ }
    @media screen and (min-width: 160.0625rem) {
      .product-card__button {
        grid-area: 0.39062vw; } }
    .product-card__button .button-product {
      pointer-events: all; }
  @media screen and (max-width: 106.25rem) {
    .product-card .availability__icon {
      width: 1rem;
      height: 1rem; } }
  @media screen and (max-width: 85rem) {
    .product-card .availability__icon {
      width: 0.75rem;
      height: 0.75rem; } }
