@mixin mediumTag {
  font-size: rem(12);
  letter-spacing: rem(1.2);


  @include mediaBigDesktop {
    font-size: big(12);
    letter-spacing: big(1.2);
  }

  @content;
}

@mixin smallTag {
  padding: rem(4) rem(8);

  font-size: rem(10);
  letter-spacing: rem(1);


  @include mediaBigDesktop {
    padding: big(4) big(8);

    font-size: big(10);
    letter-spacing: big(1);
  }

  @content;
}

@mixin XSTag {
  padding: rem(2) rem(4);

  font-size: rem(7);
  letter-spacing: rem(0.7);


  @include mediaBigDesktop {
    padding: big(2) big(4);

    font-size: big(7);
    letter-spacing: big(0.7);
  }

  @content;
}
